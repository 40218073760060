// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// scripts
require("jquery");
require("jquery-ui");
require("jquery-ujs");
require("packs/jquery/simple-lightbox");

require("bootstrap");

window.raf = require("raf");
import debounce from 'lodash.debounce';
window.debounce = debounce;

require('packs/jquery/social-share-button');
require("packs/jquery/DragDropTouch");
require("packs/jquery/script");
require('packs/jquery/core_functions');
require('packs/jquery/loader');
require('packs/jquery/multipart-form');
require('packs/jquery/js_sharing');

// styles
//import 'css/site';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
